<template>
  <div class="page-row">
    <v-container
      class="category-block-header"
      :class="{
        'd-none': $ebsn.meta(category, 'metaData.category_info.HIDE_TITLE')
      }"
    >
      <category-title :category="category" />
    </v-container>

    <category-block
      v-for="(config, idx) in category.categoryBlocks"
      :key="config.categoryBlockId"
      :config="config"
      :class="`category-block category-block-${idx}`"
    />

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_DESCRIPTION"
      tag="div"
      class="category-block-footer"
    />
  </div>
</template>
<script>
import category from "~/mixins/category";
import login from "~/mixins/login";

import { mapActions } from "vuex";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

export default {
  name: "PageRow",
  mixins: [category, login],
  components: {
    CategoryTitle
  },
  data() {
    return {
      key: 1
    };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      resetFilters: "category/resetFilters"
    })
  }
};
</script>
